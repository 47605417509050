<template>
  <div class="d-flex full-height full-width flex-1">
    <dynamic-data-table
      :can-delete="false"
      :export-file-name="`${dhmeBillOfMaterialFocusedGroup.name}_elements`"
      :has-options="true"
      :is-loading="tableLoading"
      :table-headers="tableHeaders"
      :table-records="tableRecords"
      :table-title="`${dhmeBillOfMaterialFocusedGroup.name} Elements`"
      auto-sort-column="element_type"
      :style="{ width: selectedCell ? 'calc(100% - 400px)' : '100%' }"
      :csv-export-function="exportToCsv"
    >
      <template #table-actions>
        <v-alert
          v-if="invalidData.length > 0"
          border="left"
          class="ma-0 mr-2"
          dense
          type="warning"
        >
          {{ text('incorrectObjectsDataSetWarning', invalidData.length) }}
        </v-alert>
        <v-autocomplete
          v-model="selectedModuleType"
          :items="moduleTypes"
          :placeholder="text('module')"
          clearable
          dense
          filled
          hide-details
          style="max-width: 200px"
        />
        <div v-if="selectedModuleType" class="mx-2">
          {{ moduleCount }} {{ $t('general.numberOfAbbreviation') }}
        </div>
      </template>
      <template
        v-for="tmp in tableHeaders"
        #[`header.${tmp.value}`]="{ header }"
      >
        <th :key="tmp.value">{{ tmp.text }} ({{ getHeaderTotal(tmp) }})</th>
      </template>
      <template
        v-for="tmp in tableHeaders"
        #[`item.${tmp.value}`]="{ value, rowId, item }"
      >
        <td :key="tmp.value">
          {{ value.count }}
        </td>
      </template>
      <template #item.check="{ value, rowId, item }">
        <div class="d-flex">
          <v-icon v-if="selectedModuleType" small class="mr-1">
            {{
              checkElementTypeCount(item) % 1 !== 0
                ? 'mdi-alert-circle'
                : 'mdi-check-circle'
            }}
          </v-icon>
          {{ selectedModuleType ? checkElementTypeCount(item) : '-' }}
        </div>
      </template>
      <template #table-options-menu>
        <v-dialog
          v-model="showQrDialog"
          max-width="700px"
          @keydown.esc="showQrDialog = false"
          @click:outside="showQrDialog = false"
        >
          <template #activator="{ on }">
            <v-list-item
              v-if="dhmeBillOfMaterialFocusedGroup.group_code !== '-1'"
              v-on="on"
              @click="showQrDialog = true"
            >
              <v-list-item-icon class="mr-2">
                <v-icon dense> mdi-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Download QR codes </v-list-item-title>
            </v-list-item>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              Download QR Codes
            </v-card-title>
            <v-tabs v-model="downloadTab" grow>
              <v-tab>Modules</v-tab>
              <v-tab>Elements</v-tab>
            </v-tabs>
            <div class="px-15">
              <ant-input
                label="File type"
                is-optional
                :hint="
                  downloadFileType === 'pdf'
                    ? 'QR codes will be generated and sent back in pdf form'
                    : 'QR code content will be generated and sent back in csv form'
                "
              >
                <template #input-field>
                  <v-btn-toggle
                    v-model="downloadFileType"
                    color="primary"
                    mandatory
                  >
                    <v-btn value="pdf">
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn value="csv">
                      <v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </ant-input>
              <v-tabs-items v-model="downloadTab">
                <v-tab-item>
                  <ant-input label="Benaming">
                    <template #input-field>
                      <v-select
                        v-model="downloadType"
                        :items="[
                          {
                            name: 'Type A - Hoes',
                            value: 'A',
                          },
                          {
                            name: 'Type B - Meterkast/ Technisch ruimte',
                            value: 'B',
                          },
                          {
                            name: 'Type H - Equipmentnummer',
                            value: 'H',
                          },
                        ]"
                        filled
                        item-value="value"
                        item-text="name"
                        placeholder="Benaming"
                      ></v-select>
                    </template>
                  </ant-input>
                </v-tab-item>
                <v-tab-item>
                  <ant-input label="Benaming">
                    <template #input-field>
                      <v-select
                        v-model="downloadType"
                        :items="[
                          {
                            name: 'Type C - Algemeen',
                            value: 'C',
                          },
                          {
                            name: 'Type I - Betonvloeren',
                            value: 'I',
                          },
                        ]"
                        filled
                        item-value="value"
                        item-text="name"
                        placeholder="Benaming"
                      ></v-select>
                    </template>
                  </ant-input>
                </v-tab-item>
              </v-tabs-items>
            </div>

            <v-card-actions class="ant-dialog-actions-bg ant-border-top">
              <v-spacer />
              <v-btn color="error" text small @click="showQrDialog = false">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                elevation="0"
                small
                :disabled="!downloadType"
                @click="downloadQrCodes"
              >
                Download</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-list-item
          v-if="dhmeBillOfMaterialFocusedGroup.group_code !== '-1'"
          @click="exportGroupQrCodes"
        >
          <v-list-item-icon class="mr-2">
            <v-icon dense> mdi-qrcode </v-icon>
          </v-list-item-icon>
          <v-list-item-title> Export QR codes (Old) </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="
            tableTabState === 'production' && dhmeBillOfMaterialProductionTask
          "
          :disabled="supplierWorkPackage"
          @click="createWorkPackage"
        >
          <v-list-item-icon class="mr-2">
            <v-icon dense> mdi-package-variant-closed-plus </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            supplierWorkPackage
              ? text('WorkPackageSubmitted')
              : text('createWorkPackage')
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="exportWithdrawalStatement">
          <v-list-item-icon class="mr-2">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportWithdrawalStatement')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportWeeklyStatement">
          <v-list-item-icon class="mr-2">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportWeeklyStatement')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportWeeklyPlanning">
          <v-list-item-icon class="mr-2">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportWeeklyPlanning')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportModuleContents">
          <v-list-item-icon class="mr-2">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportModuleContents')
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </dynamic-data-table>
  </div>
</template>

<script>
import DynamicDataTable from '../../../components/DynamicDataTable.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer.vue';
import { getObjectsWithMappingLevel } from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_BILL_OF_MATERIAL } from '@/modules/modules';
import AntInput from '@/components/AntInput.vue';
import { importTasksV2 } from '@/services/api/v2/tasks.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { DateTime } from 'luxon';

export default {
  name: 'DhmeBillOfMaterialElements',
  components: { AntInput, DynamicDataTable },
  props: {
    tableTabState: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      assemblyStartDate: undefined,
      assemblyLocationWorkload: undefined,
      assemblyStartHour: undefined,
      tableLoading: false,
      tableRecords: [],
      tableHeaders: [],
      selectedCell: undefined,
      detailViewerRendered: false,
      showQrDialog: false,
      downloadType: null,
      downloadTab: 0,
      downloadFileType: 'pdf',
      moduleTypes: [],
      selectedModuleType: null,
      moduleCount: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeBillOfMaterialFocusedGroup',
      'dhmeBillOfMaterialResourceGroups',
      'dhmeBillOfMaterialModules',
      'dhmeBillOfMaterialNonModules',
      'dhmeBillOfMaterialElements',
      'dhmeBillOfMaterialResourceGroupElements',
      'dhmeBillOfMaterialPlacementTasks',
      'dhmeBillOfMaterialAssemblyTasks',
      'dhmeBillOfMaterialProductionTask',
      'dhmeBillOfMaterialWorkPackagesTasks',
      'dhmeBillOfMaterialAssemblyPhase',
    ]),

    supplierWorkPackage() {
      return this.dhmeBillOfMaterialWorkPackagesTasks.find(
        (t) =>
          t.task_type.custom_2 ===
          this.dhmeBillOfMaterialFocusedGroup.group_code
      );
    },

    elementSchedulePlacementHeaders() {
      let headers = [
        {
          text: this.text('table.elementType'),
          value: 'element_type',
        },
        {
          text: this.text('table.category'),
          value: 'element_category',
        },
        {
          text: this.text('table.total'),
          value: 'total',
          hasHeaderSlot: true,
        },
        {
          text: 'check',
          value: 'check',
          hasSlot: true,
        },
      ];
      this.dhmeBillOfMaterialPlacementTasks.forEach((task) => {
        headers.push({
          text: task.title,
          value: task.title,
          width: '100px',
          phase: true,
          name: task.title,
          hasHeaderSlot: true,
          hasSlot: true,
          sortable: false,
        });
      });

      return headers;
    },
    elementScheduleProductionHeaders() {
      const start = DateTime.fromFormat(
        this.dhmeBillOfMaterialAssemblyTasks[0].planned_start,
        'yyyy-MM-dd HH:mm:ss'
      );
      const end = DateTime.fromFormat(
        this.dhmeBillOfMaterialAssemblyTasks[
          this.dhmeBillOfMaterialAssemblyTasks.length - 1
        ].planned_end,
        'yyyy-MM-dd HH:mm:ss'
      );

      // Calculate the number of weeks between the start and end dates
      let assemblyWeeks = Math.ceil(end.diff(start, 'weeks').weeks);

      let headers = [
        {
          text: this.text('table.elementType'),
          value: 'element_type',
        },
        {
          text: this.text('table.category'),
          value: 'element_category',
        },
        {
          text: this.text('table.total'),
          value: 'total',
          hasHeaderSlot: true,
        },
        {
          text: 'check',
          value: 'check',
          hasSlot: true,
        },
      ];

      for (let i = 0; i <= assemblyWeeks; i++) {
        let currentWeekDate = start.plus({ weeks: i });

        // Explicitly set `weekStart` to Monday at the specified start time
        let weekStart = currentWeekDate
          .set({ weekday: 1 }) // Set to Monday
          .set({
            hour: this.assemblyStartHour,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

        // Explicitly set `weekEnd` to Friday of the same week at the specified end time
        let weekEnd = weekStart.plus({
          days: 4,
          hours: this.assemblyLocationWorkload,
        });

        headers.push({
          text: `PW${i + 1} | KW${weekStart.weekNumber}`,
          value: `week_${weekStart.weekNumber}`,
          width: '100px',
          week: weekStart.weekNumber,
          week_start: weekStart.toFormat('yyyy-MM-dd HH:mm:ss'),
          week_end: weekEnd.toFormat('yyyy-MM-dd HH:mm:ss'),
          date: currentWeekDate.toISO(),
          hasHeaderSlot: true,
          hasSlot: true,
          sortable: false,
        });
      }

      return headers;
    },
    invalidData() {
      return [];
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_BILL_OF_MATERIAL
      ).id;
    },
  },
  watch: {
    dhmeBillOfMaterialFocusedGroup() {
      switch (this.tableTabState) {
        case 'production':
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.calculatePlacementSchedule();
          break;
      }
    },
    async selectedModuleType(value) {
      let { modules } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_MODULES',
            project: this.project.id,
            as: 'modules',
            columns: [
              {
                name: 'module_type',
                conditions: [
                  {
                    operator: '=',
                    value: this.selectedModuleType,
                  },
                ],
              },
            ],
          },
        ],
      });

      this.moduleCount = modules.records.length;
      switch (this.tableTabState) {
        case 'production':
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.calculatePlacementSchedule();
          break;
      }
    },
    tableTabState() {
      switch (this.tableTabState) {
        case 'production':
          this.tableHeaders = this.elementScheduleProductionHeaders;
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.tableHeaders = this.elementSchedulePlacementHeaders;
          this.calculatePlacementSchedule();
          break;
      }
    },
  },
  async mounted() {
    if (this.dhmeBillOfMaterialAssemblyPhase.length === 1) {
      this.assemblyStartHour =
        this.dhmeBillOfMaterialAssemblyPhase[0].task_type.custom_5;
      this.assemblyLocationWorkload =
        this.dhmeBillOfMaterialAssemblyPhase[0].task_type.custom_6;

      this.assemblyStartDate = moment(
        this.dhmeBillOfMaterialAssemblyPhase[0].planned_start
      ).set({
        hour: this.assemblyStartHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let { modules } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_MODULES',
            project: this.project.id,
            as: 'modules',
            columns: [
              {
                name: 'module_type',
              },
            ],
          },
        ],
      });

      this.moduleTypes = [
        ...new Set(modules.records.map((x) => x.module_type)),
      ];

      this.calculateProductionSchedule();
      this.tableHeaders = this.elementScheduleProductionHeaders;
    } else {
      this.$store.commit('showNotification', {
        content: 'Assembly phase not found',
        color: 'warning',
      });
    }
  },
  methods: {
    exportToCsv() {
      let copy = JSON.parse(JSON.stringify(this.tableRecords));
      copy.forEach((x) => {
        Object.keys(x).forEach((key) => {
          if (key.startsWith('week_')) {
            x[key] = x[key].count;
          }
        });
      });
      return copy;
    },
    checkElementTypeCount(item) {
      return (item.total / this.moduleCount).toFixed(2);
    },
    exportGroupQrCodes() {
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'downloadGroupQrCodes',
        {
          group: this.dhmeBillOfMaterialFocusedGroup.group_code,
        }
      ).then((response) => {
        this.$store.commit('showNotification', {
          content: response.message,
          color: 'success',
        });
      });
    },
    downloadQrCodes() {
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'downloadQrCodes',
        {
          type: this.downloadType,
          group: this.dhmeBillOfMaterialFocusedGroup.group_code ?? null,
          fileType: this.downloadFileType,
        }
      ).then((response) => {
        this.$store.commit('showNotification', {
          content: response.message,
          color: 'success',
        });
        this.showQrDialog = false;
        this.downloadType = null;
        this.downloadFileType = 'pdf';
        this.downloadTab = 0;
      });
    },
    async createWorkPackage() {
      let data = [
        {
          title: `Work package (${this.dhmeBillOfMaterialFocusedGroup.group_code})`,
          parent: this.dhmeBillOfMaterialProductionTask.id,
          project: this.project.id,
          options: {
            type: 'dhme-work-package',
            custom_1: 'request',
            custom_2: this.dhmeBillOfMaterialFocusedGroup.group_code,
          },
        },
      ];

      const workPackages = await importTasksV2(data);
      this.$store.commit(
        'module_bill_of_material_work_package_request_success',
        workPackages
      );

      this.$store.commit('showNotification', {
        content: 'Work package request sent successfully',
        color: 'success',
      });
    },
    exportWithdrawalStatement() {
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateWithdrawalStatement',
        {
          elements: this.tableRecords,
          group: this.dhmeBillOfMaterialFocusedGroup,
        }
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Uittrekstaat_${this.dhmeBillOfMaterialFocusedGroup.name}_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    },
    exportModuleContents() {
      let data = {
        moduleTypes: [],
        groups: [],
      };
      let moduleTypes = [
        ...new Set(
          this.dhmeBillOfMaterialElements
            .filter(
              (item) =>
                item.module_type &&
                !item.module_type.startsWith('NonModuleObject')
            )
            .map((item) => item.module_type)
            .sort((a, b) => a.localeCompare(b))
        ),
      ];

      data.groups = [...this.dhmeBillOfMaterialResourceGroups].map(
        ({ name, group_code }) => ({ name, group_code })
      );
      data.groups.forEach((group) => {
        group.total = 0;
      });

      moduleTypes.forEach((type) => {
        let dataObject = {
          moduleType: type,
          numberOf: [
            ...new Set(
              this.dhmeBillOfMaterialElements
                .filter((item) => item.module_type === type && item.module_id)
                .map((item) => item.module_id)
            ),
          ].length,
          groups: [],
        };
        let groups = [...this.dhmeBillOfMaterialResourceGroups].sort(
          (a, b) => a.group_code - b.group_code
        );
        groups.forEach((group) => {
          let x = {
            name: group.name,
            code: group.group_code,
            elements: [
              ...new Set(
                this.dhmeBillOfMaterialElements
                  .filter(
                    (item) => item.module_type === type && item.element_type
                  )
                  .map((item) => item.element_type)
              ),
            ]
              .filter(
                (item) =>
                  this.dhmeBillOfMaterialResourceGroupElements.findIndex(
                    (record) =>
                      item === record.element_type &&
                      record.group_code === group.group_code
                  ) !== -1
              )
              .sort((a, b) => a.localeCompare(b)),
          };
          dataObject.groups.push(x);
          let y = data.groups.find(
            (item) => item.group_code === group.group_code
          );
          if (y.total < x.elements.length) {
            y.total = x.elements.length;
          }
        });
        data.moduleTypes.push(dataObject);
      });

      data.groups.sort((a, b) => a.group_code - b.group_code);

      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateModuleContents',
        data
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `inhoud_modules_${moment().format('YYYY-MM-DD')}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    },
    exportWeeklyStatement() {
      let data = {
        weeks: [],
        group: this.dhmeBillOfMaterialFocusedGroup,
      };

      let start = moment(this.dhmeBillOfMaterialAssemblyTasks[0].planned_start);
      let end = moment(
        this.dhmeBillOfMaterialAssemblyTasks[
          this.dhmeBillOfMaterialAssemblyTasks.length - 1
        ].planned_start
      );

      let productionWeekCount = Math.ceil(end.diff(start, 'weeks', true));

      let monday = start.clone().day('Monday');

      for (let i = 0; i <= productionWeekCount; i++) {
        let object = {
          productionWeek: i + 1,
          calendarWeek: monday.isoWeek(),
        };

        let weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
        weekDays.forEach((day) => {
          let date = monday.clone().day(day).format('YYYY-MM-DD');

          let moduleBuildNrs = this.dhmeBillOfMaterialAssemblyTasks
            .filter((task) =>
              moment(moment(task.planned_start).format('YYYY-MM-DD')).isSame(
                date
              )
            )
            .map((task) => task.sbscode?.code);

          let modules = this.dhmeBillOfMaterialModules.filter((module) =>
            moduleBuildNrs.includes(module.build_nr)
          );

          modules.forEach((module) => {
            let tmp = [
              ...new Map(
                this.dhmeBillOfMaterialElements
                  .filter(
                    (item) =>
                      item.module_id === module.module_id &&
                      item.element_id &&
                      item.element_type
                  )
                  .map((record) => [record.element_id, record.element_type])
              ).values(),
            ];

            module.elements = tmp
              .filter(
                (element) =>
                  this.dhmeBillOfMaterialResourceGroupElements.findIndex(
                    (item) =>
                      item.element_type === element &&
                      item.group_code ===
                        this.dhmeBillOfMaterialFocusedGroup.group_code
                  ) !== -1
              )
              .sort((a, b) => a.localeCompare(b));
          });
          object[day] = {
            date: date,
            modules: modules.sort(
              (a, b) => a.assembly_sequence - b.assembly_sequence
            ),
          };
        });

        data.weeks.push(object);
        monday.add(1, 'week');
      }

      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateWeeklyStatement',
        data
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Weekstaat_${this.dhmeBillOfMaterialFocusedGroup.name}_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    },
    exportWeeklyPlanning() {
      let data = {
        weeks: [],
        group: this.dhmeBillOfMaterialFocusedGroup,
      };

      let start = moment(this.dhmeBillOfMaterialAssemblyTasks[0].planned_start);
      let end = moment(
        this.dhmeBillOfMaterialAssemblyTasks[
          this.dhmeBillOfMaterialAssemblyTasks.length - 1
        ].planned_start
      );

      let productionWeekCount = Math.ceil(end.diff(start, 'weeks', true));

      let monday = start.clone().day('Monday');

      for (let i = 0; i <= productionWeekCount; i++) {
        let object = {
          productionWeek: i + 1,
          calendarWeek: monday.isoWeek(),
        };

        let weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
        weekDays.forEach((day) => {
          let date = monday.clone().day(day).format('YYYY-MM-DD');

          let buildNrs = this.dhmeBillOfMaterialAssemblyTasks
            .filter((task) =>
              moment(moment(task.planned_start).format('YYYY-MM-DD')).isSame(
                date
              )
            )
            .map((task) => task.sbscode?.code);

          let modules = this.dhmeBillOfMaterialModules.filter((module) =>
            buildNrs.includes(module.build_nr)
          );

          modules.forEach((module) => {
            let tmp = [
              ...new Map(
                this.dhmeBillOfMaterialElements
                  .filter(
                    (item) =>
                      item.module_id === module.module_id &&
                      item.element_id &&
                      item.element_type
                  )
                  .map((record) => [record.element_id, record.element_type])
              ).values(),
            ];

            module.elements = tmp
              .filter(
                (element) =>
                  this.dhmeBillOfMaterialResourceGroupElements.findIndex(
                    (item) =>
                      item.element_type === element &&
                      item.group_code ===
                        this.dhmeBillOfMaterialFocusedGroup.group_code
                  ) !== -1
              )
              .sort((a, b) => a.localeCompare(b));
          });
          object[day] = {
            date: date,
            modules: modules.sort(
              (a, b) => a.assembly_sequence - b.assembly_sequence
            ),
          };
        });

        data.weeks.push(object);
        monday.add(1, 'week');
      }

      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateWeeklyPlanning',
        data
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Weekplanning_${this.dhmeBillOfMaterialFocusedGroup.name}_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.BillOfMaterial.elementSchedule.${path}`, params);
    },
    viewerRendered(value) {
      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
      this.$refs['forge-viewer'].viewerService.setViewTo('top back right');
      document.getElementById('guiviewer3d-toolbar').style.visibility =
        'hidden';
      this.$refs['forge-viewer'].performanceModeToggle = true;
      this.detailViewerRendered = true;
    },
    calculatePlacementSchedule() {
      this.tableRecords = [];
      this.tableLoading = true;

      const body = {
        project: {
          id: this.project.id,
        },
        group: this.dhmeBillOfMaterialFocusedGroup.group_code,
        moduleType: this.selectedModuleType,
        phases: this.dhmeBillOfMaterialPlacementTasks.map((task) => {
          return {
            id: task.id,
            name: task.title,
          };
        }),
      };
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getGroupElementPlacementSchedule',
        body
      ).then((response) => {
        response.forEach((item) => {
          item.total = 0;
          this.dhmeBillOfMaterialPlacementTasks.forEach((task) => {
            item.total += item[task.title].count;
          });
        });
        this.tableRecords = response;
        this.tableLoading = false;
      });
    },
    calculateProductionSchedule() {
      this.tableRecords = [];
      this.tableLoading = true;

      const body = {
        project: {
          id: this.project.id,
        },
        group: this.dhmeBillOfMaterialFocusedGroup.group_code,
        moduleType: this.selectedModuleType,
        weeks: this.elementScheduleProductionHeaders
          .filter((header) => {
            return header.week;
          })
          .map((week) => {
            return {
              week: week.week,
              week_start: week.week_start,
              week_end: week.week_end,
            };
          }),
      };
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getGroupElementProductionSchedule',
        body
      ).then((response) => {
        if (response.length > 0) {
          let weeks = Object.keys(response[0]).filter((key) =>
            key.startsWith('week_')
          );
          response.forEach((item) => {
            let modules = [];
            item.total = 0;
            weeks.forEach((week) => {
              item.total += item[week].count;
              modules = [...modules, item[week].modules];
            });
            let array = modules.flatMap((x) => x);
            const duplicateElements = array.filter(
              (item, index) => array.indexOf(item) !== index
            );
          });
        }
        this.tableRecords = response;
        this.tableLoading = false;
      });
    },
    getHeaderTotal(header) {
      let sum = 0;
      this.tableRecords.forEach((record) => {
        if (header.value.startsWith('week_')) {
          sum += record[header.value].count;
        } else {
          sum += record.total;
        }
      });
      return sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.supplier-header {
  font-size: 18px;
  font-weight: 500;
}

.supplier-sub-header {
  font-style: italic;
}

.clickable-cell {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
